import React, { useContext, useEffect, useState } from 'react'
import "./style.css"
import Logo from "../../assets/images/EarthScene.png"
import { useNavigate } from 'react-router-dom'
import { SignupForm, SignupInfoForm } from '../../components'
import { auth, db } from '../../lib/firebase'
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { CSSTransition } from "react-transition-group"
import { doc, serverTimestamp, setDoc } from 'firebase/firestore'

const Signup = () => {
  const navigate = useNavigate("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const { user, setUser } = useContext(AuthContext)
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [bio, setBio] = useState("")
  const [photoURL, setPhotoURL] = useState("")
  const [userCreated, setUserCreated] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [activeMenu, setActiveMenu] = useState(
    "main"
  );
  const [displayName, setDisplayname] = useState("")
  const [termsChecked, setTermsChecked] = useState(false)
  const [fullname, setFullName] = useState({
    first: "",
    last: ""
  })

  useEffect(() => {
    const storedUserCreated = JSON.parse(localStorage.getItem('userCreated'));
  
    setUserCreated(storedUserCreated || false);
  }, [userCreated]);
  
  useEffect(() => {
    if (error) {
      setActiveMenu("main")
    }
  }, [activeMenu, error])

  const createUser = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email && password && password.length >= 6 && fullname.first && fullname.last && termsChecked) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (!emailRegex.test(email)) {
      setError(true);
      setErrorMessage('Please provide a valid email address.');
      setLoading(false)
      return;
    }

    if (!email || !password || !displayName || !fullname.first || !fullname.last) {
      setError(true);
      setErrorMessage('Please fill in all required fields.');
      setLoading(false)
      return;
    }
  
    if (password.length < 6) {
      setError(true);
      setErrorMessage('Password should be at least 6 characters long.');
      setLoading(false)
      return;
    }
  
    if (displayName.length < 2 || fullname.first.length < 2 || fullname.last.length < 2) {
      setError(true);
      setErrorMessage('Display name and full name should be at least 2 characters long.');
      setLoading(false)
      return;
    }
  
    if (!termsChecked) {
      setError(true);
      setErrorMessage('Please accept the terms of agreement.');
      setLoading(false)
      return;
    }
    
    try {
      const createUserInWp = async (email, displayName, password) => {
        await axios.post('https://earthscene.co/wp-json/firebase-sync/v1/create-user', {
            username: displayName,
            email: email,
            password: password,
            roles: ['author']
        });
    
        const wp_api_url = "https://earthscene.co/wp-json/jwt-auth/v1/token"
    
        const { data } = await axios.post(wp_api_url, {
          username: displayName,
          password: password
        }) 
    
        return data.token
    }
    
    const addUserToDb = async (email, displayName, token, fullname, uid) => {
      console.log("uid", uid)

        const userData = {
            displayName: displayName,
            email: email,
            photoURL: "",
            fullname: fullname,
            bio: "",
            location: [],
            token: token,
            website: "",
            dateOfBirth: "",
            gender: "",
            saved: [],
            interests: [],
            languages: [],
            likedPosts: [],
            socialLinks: {
              facebook: "",
              twitter: "",
              instagram: "",
              linkedin: "",
            },
            travelHistory: "",
            followers: 0,
            following: 0,
            postsCount: 0,
            joinedAt: serverTimestamp(),
          };
    
          try {
            const userDocRef = doc(db, "users", uid);
            await setDoc(userDocRef, userData);

            console.log("successfully created document")
          } catch (error) {
            console.log("Error creating user document:", error)
            setError(true)
            setErrorMessage("error with db")
          }
    }

    const { user: userData } = await createUserWithEmailAndPassword(auth, email, password);
    const { uid } = userData;

    const token = await createUserInWp(email, displayName, password)
    await addUserToDb(email, displayName, token, fullname, uid);

      setUser({
        ...user,
        photoURL: userData.photoURL, 
        email: userData.email,
        displayName: userData.displayName,
        uid: userData.uid,
        token: token
      })

      setActiveMenu('secondary');
    } catch (error) {
      console.error('Error during user creation:', error.message);
  
      if (error.code) {
        // Handle specific Firebase error codes
        const handleFirebaseError = (errorMessage) => {
          if (errorMessage.includes('auth/email-already-in-use')) {
            return 'Email address is already in use. Please try another one.';
          } else if (errorMessage.includes('auth/invalid-email')) {
            return 'Please provide a valid email address.';
          } else if (errorMessage.includes('auth/weak-password')) {
            return 'Password should be at least 6 characters long.';
          } else {
            return errorMessage;
          }
        };
  
        setError(true);
        setErrorMessage(handleFirebaseError(error.message));
      } else {
        // Handle other errors
        setError(true);
        setErrorMessage('An unexpected error occurred. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='w-screen h-screen flex bigboi'>
      <div className='w-[100%] h-screen signupImage'>
      <img src={require("../../assets/images/signup.jpg")} className='w-[100%] h-[100%] object-cover' />
      </div>
      <div className='w-[100%] h-screen py-[45px] px-[43px] flex flex-col'>
        <div className='w-[100%]'>
          <img src={Logo} alt='Logo' className='w-[80px] h-[80px]' />
        </div>
        <CSSTransition
          in={activeMenu === 'main'}
          timeout={500}
          classNames={{
            enter: 'main-primary-enter',
            enterActive: 'main-primary-enter-active',
            exit: 'main-primary-exit',
            exitActive: 'main-primary-exit-active',
          }}
          unmountOnExit
        >
        <div className='main w-[100%]'>
            <div className='flex items-end justify-between w-[100%]'>
              <h1 className='mt-[20px] text-[33px] mb-[-6px]'>Create an Account</h1>
              <p className='flex text-[15px] mt-[10px] text-[#999]'>Already have an account? <span onClick={() => navigate("/Login")} className='text-[15px]' style={{ margin: "0 5px", cursor: "pointer", color:  "#ef4444" }}>Log in</span></p>
            </div>
            <SignupForm 
              email={email}
              setEmail={setEmail}
              password={password}
              loading={loading}
              setLoading={setLoading}
              setPassword={setPassword}
              error={error}
              setError={setError}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setActiveMenu={setActiveMenu}
              displayName={displayName}
              setDisplayname={setDisplayname}
              fullname={fullname}
              setFullName={setFullName}
              termsChecked={termsChecked}
              setTermsChecked={setTermsChecked}
              createUser={createUser}
            />
        </div>
      </CSSTransition>

          <CSSTransition
            in={activeMenu === 'secondary'}
            timeout={500}
            appear
            classNames={{
              enter: 'main-secondary-enter',
              enterActive: 'main-secondary-enter-active',
              exit: 'main-secondary-exit',
              exitActive: 'main-secondary-exit-active',
            }}
            unmountOnExit
          >
              <SignupInfoForm 
                loading={loading}
                setLoading={setLoading}
                displayName={displayName}
                photoURL={photoURL}
                setPhotoURL={setPhotoURL}
                setUserCreated={setUserCreated}
                bio={bio}
                setBio={setBio}
              />
          </CSSTransition>
      </div>
    </div>
  )
}

export default Signup