import React, { useContext, useState, useRef, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ArrowIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import "./style.css";
import Logo from "../../assets/images/EarthScene.png";
import { useNavigate } from 'react-router-dom';
import SearchIcon from "../../assets/icons/Search.svg";
import { AuthContext } from '../../context/AuthContext';
import { CSSTransition } from 'react-transition-group';
import { auth } from '../../lib/firebase';
import { signOut } from 'firebase/auth';
import { logout } from '../../functions/logout';

const Header = () => {
  const navigate = useNavigate("");
  const { user, setUser } = useContext(AuthContext)
  const [showDropdown, setShowDropdown] = useState(false);
  const [showResponsiveDropdown, setShowResponsiveDropdown] = useState(false)

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleResize = () => {
    if (window.innerWidth >= 797) {
      setShowResponsiveDropdown(false); // Set showResponsiveDropdown to false when screen width is 797px or more
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  function DropdownMenu() {
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);
  
    useEffect(() => {
      setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    }, [])
  
    function calcHeight(el) {
      const height = el.offsetHeight;
      setMenuHeight(height);
    }
  
    function DropdownItem(props) {
      return (
        <a 
          href="#" 
          className={!props.className ? `menu-item` : `menu-item ${props.className}`} 
          onClick={(e) => {
            e.preventDefault()

            if (props.goToMenu) {
              props.goToMenu && setActiveMenu(props.goToMenu)
            } else {
              props.onClick()
            }
          }}
        >
          <span className="icon-button mr-[5px]">{props.leftIcon}</span>
          {props.children}
          <span className="icon-right">{props.rightIcon}</span>
        </a>
      );
    }

    return (
      <div className="dropdown" style={{ height: menuHeight }} ref={dropdownRef}>
        <CSSTransition
          in={activeMenu === 'main'}
          timeout={500}
          classNames="menu-primary"
          unmountOnExit
          onEnter={calcHeight}>
          <div className="menu">
            <DropdownItem className="Profile" onClick={() => navigate(`/profile/${user.displayName}`)}>
              <img 
                src={user?.photoURL}
                className='w-[50px] h-[50px] rounded-full object-cover'
              />
              <h1 className='ml-[10px] text-[25px] font-bold'>{user?.displayName}</h1>
            </DropdownItem>
            <DropdownItem
              leftIcon={<SettingsIcon />}
              goToMenu="settings">
              Settings
            </DropdownItem>
            <DropdownItem
              leftIcon="🦧"
              rightIcon={<PersonIcon />}
              goToMenu="animals">
              Animals
            </DropdownItem>
            <DropdownItem
              leftIcon={<LogoutIcon />}
              onClick={() => {
                logout(navigate, "/signup")
                setUser(null)
              }}
            >
              Logout
            </DropdownItem>
          </div>
        </CSSTransition>
  
        <CSSTransition
          in={activeMenu === 'settings'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
          onEnter={calcHeight}>
          <div className="menu">
            <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
              <h2>My Tutorial</h2>
            </DropdownItem>
          </div>
        </CSSTransition>
  
        <CSSTransition
          in={activeMenu === 'animals'}
          timeout={500}
          classNames="menu-secondary"
          unmountOnExit
          onEnter={calcHeight}>
          <div className="menu">
            <DropdownItem goToMenu="main" leftIcon={<ArrowIcon />}>
              <h2>Animals</h2>
            </DropdownItem>
            <DropdownItem leftIcon="🦘">Kangaroo</DropdownItem>
            <DropdownItem leftIcon="🐸">Frog</DropdownItem>
            <DropdownItem leftIcon="🦋">Horse?</DropdownItem>
            <DropdownItem leftIcon="🦔">Hedgehog</DropdownItem>
          </div>
        </CSSTransition>
      </div>
    );
  }

  return (
    <div className='header w-screen bg-white h-[95px] flex items-center pl-[15px] justify-between border-b-[1px] border-[#ccc]'>
      <div className='flex items-center cursor-pointer' onClick={() => navigate("/")}>
        <img src={Logo} alt="Logo" className='w-[60px] cursor-pointer'/>
        <h1 className="earthscene__text text-[30px] ml-[15px] earthscene">EarthScene</h1>
      </div>
      <div
        className='search w-[45%] h-[50px] rounded-[300px] border-[1px] border-[#ccc] bg-[#f5f5f5] outline-none px-[20px] flex items-center'
      >
        <img src={SearchIcon} alt='SearchIcon' className='mr-[10px]' />
        <input 
          type="text" 
          placeholder='Search...'
          className='w-[100%] h-[100%] outline-none bg-transparent'
        />
      </div>
      <div className='iconContainer w-[20%] h-[100%] pr-[10px] flex items-center justify-between mr-[20px]'>
      {user ? (
        <>
        <div className="iconsContainer flex items-center">      
          <div className='pointer w-[60px] h-[60px] border-[1px] border-[#ccc] items-center justify-center flex rounded-full mr-[10px]'><SettingsIcon style={{ fontSize: 35, color: "#999" }} /></div>
          <div className='pointer mr-[10px] w-[60px] h-[60px] border-[1px] relative border-[#ccc] items-center justify-center flex rounded-full'><NotificationsActiveOutlinedIcon style={{ fontSize: 35, color: "#999" }} /><div className='absolute w-[25px] h-[25px] rounded-full items-center flex justify-center bg-red-500 right-[-5px] top-[-8px] text-[#fff]'>5</div></div>
            <div className='hamburger mr-[10px] w-[60px] h-[60px] border-[1px] relative border-[#ccc] items-center cursor-pointer justify-center flex rounded-full' onClick={() => setShowResponsiveDropdown(!showResponsiveDropdown)}>
              <MenuIcon style={{ fontSize: 35, color: "#999" }} />
            </div>
            {showResponsiveDropdown ? (
            <div className="p-[10px] shadow-lg w-[100%] responsive h-[170px] absolute top-[95px] left-[0px]">
              <div className="w-[100%] h-[50px] responsiveItem">
                <SettingsIcon style={{ fontSize: 35, color: "#999" }} />
                <p>Settings</p>
              </div>
              <div className="w-[100%] h-[50px] responsiveItem">
                <div className="responsiveItem2 flex-1">
                  <NotificationsActiveOutlinedIcon style={{ fontSize: 30, color: "#999", marginRight: 5 }} />
                  <p>Notifications</p>
                </div>
                <div className='w-[45px] h-[25px] rounded-full items-center flex justify-center bg-red-500 right-[-5px] top-[-8px] text-[#fff]'>5</div>
              </div>
              <div className="w-[100%] h-[50px] responsiveItem">
                <LanguageOutlinedIcon style={{ fontSize: 30, color: "#999", marginRight: 5 }} />
                <p>Languages</p>
              </div>
            </div>
            ) : null}
          <div className='mr-[10px] pointer w-[60px] h-[60px] relative border-[1px] border-[#ccc] items-center justify-center flex rounded-full'><LanguageOutlinedIcon style={{ fontSize: 40, color: "#999" }} /></div>
          <div className={`cursor-pointer mr-[10px] w-[60px] h-[60px] relative border-[1px] border-[#ccc] items-center justify-center flex rounded-full`} onClick={toggleDropdown}>
          <img
            src={user?.photoURL}
            alt="Profile Pic"
            className='h-[60px] w-[60px] rounded-full object-cover'
          />
        </div>
        </div>      
        <div className="relative">
          {user !== null && showDropdown && (
            <DropdownMenu />
          )}
        </div>
        </>
      ) : (
        <div className="flex justify-end w-[105%]">
        <button className="signup__button hover h-[45px] w-[100px] bg-red-600 rounded-full text-[#fff] font-semibold" onClick={() => navigate("/signup")}>
          Signup
        </button>
      </div>
      )}
      </div>
    </div>
  );
}

export default Header;
