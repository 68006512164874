import "./App.css"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home, Signup, Login, Profile, Saved } from "./app/screens";

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/profile/:uid" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/saved" element={<Saved />} />
        </Routes>
    </Router>
  );
}

export default App;
