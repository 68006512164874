import React, { useContext, useState } from 'react'
import "./style.css"
import LogoutRed from "../../assets/icons/Logout-red.svg"
import { AuthContext } from '../../context/AuthContext'

const ProfileSidebar = ({ selectedItem, setSelectedItem }) => {
  const { user } = useContext(AuthContext)

  return (
    <div className='sidebar w-[20%] h-[100%] shadow-xl py-[15px] p-[10px] flex-col pt-[30px] justify-between'>
        <div>
            <div className='w-[100%] h-[25%] mb-[20px] flex items-center justify-center flex-col'>
              <img src={user?.photoURL} alt='' className='rounded-full w-[100px] h-[100px] object-cover mb-[10px]' />
              <h1 className='text-[23px] text-[#858585] font-normal'>{user?.displayName}</h1>
            </div>
            <div onClick={() => setSelectedItem("profile")} className={`sidebarItem ${selectedItem === "profile" && "selectedItem"} flex items-center px-[15px] w-[100%] h-[60px]`}>Profile</div>
            <div onClick={() => setSelectedItem("links")} className={`sidebarItem ${selectedItem === "links" && "selectedItem"} flex items-center px-[15px] w-[100%] h-[60px]`}>Links</div>
            <div onClick={() => setSelectedItem("password")} className={`sidebarItem ${selectedItem === "password" && "selectedItem"} flex items-center px-[15px] w-[100%] h-[60px]`}>Password</div>
            <div onClick={() => setSelectedItem("settings")} className={`sidebarItem ${selectedItem === "settings" && "selectedItem"} flex items-center px-[15px] w-[100%] h-[60px]`}>Settings</div>
            <div onClick={() => setSelectedItem("security")} className={`sidebarItem ${selectedItem === "security" && "selectedItem"} flex items-center px-[15px] w-[100%] h-[60px]`}>Security</div>
        </div>
        <div className='sidebarItem logout flex items-center px-[15px] w-[100%] h-[60px]'>
          <img src={LogoutRed} className="w-[25px] h-[25px] mr-[10px]" />
          Logout
        </div>
    </div>
  )
}

export default ProfileSidebar