import React from 'react'
import "./style.css"

function FollowButton({ text, primaryColor, followed }) {
  return (
    <>
      {!followed ? (
        <button className={`unfollowed w-[120px] h-[40px] border-[#e53e3e] border-[1px] rounded-full`} style={{ backgroundColor: primaryColor }}>
          <h1 className='text-[#fff]'>{text}</h1>
        </button>
      ) : (
        <button className={`followed w-[120px] h-[40px] border-[#e53e3e] border-[1px] rounded-full`}>
          <h1 className='text-[#e53e3e]'>{text}</h1>
        </button>
      )}
    </>
  )
}

export default FollowButton