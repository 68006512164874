import HomeFilled from "../../assets/icons/Home-filled.svg"
import HomeOutlined from "../../assets/icons/Home-outlined.svg"
import MessagesFilled from "../../assets/icons/Messages-filled.svg"
import MessagesOutlined from "../../assets/icons/Messages-outlined.svg"
import ExploreFilled from "../../assets/icons/Explore-filled.svg"
import ExploreOutlined from "../../assets/icons/Explore-outlined.svg"
import LiveFilled from "../../assets/icons/Live-filled.svg"
import LiveOutlined from "../../assets/icons/Live-outlined.svg"
import SavedFilled from "../../assets/icons/Saved-filled.svg"
import SavedOutlined from "../../assets/icons/Saved-outlined.svg"
import { useNavigate } from "react-router-dom"

export const menu = [
    {
        name: "Home",
        iconOutlined: HomeOutlined,
        iconFilled: HomeFilled
    },
    {
        name: "Message",
        iconOutlined: MessagesOutlined,
        iconFilled: MessagesFilled
    },
    {
        name: "Saved",
        iconOutlined: SavedOutlined,
        iconFilled: SavedFilled,
    }
]