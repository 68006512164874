import { signOut } from "firebase/auth";
import { auth } from "../lib/firebase";
import { AuthContext } from "../context/AuthContext";

export const logout = async (navigate, page) => {
    try {
        await signOut(auth); // Sign out the user

        await navigate(page); // Navigate to the signup page or any other route
    } catch (error) {
        console.error('Error logging out:', error);
    }
}