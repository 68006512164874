import React, { useContext, useEffect, useState } from 'react';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { menu } from './menu';
import LogoutOutlined from "../../assets/icons/Logout-outlined.svg"
import SettingsOutlined from "../../assets/icons/Settings-outlined.svg"
import "./style.css"
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../functions/logout';

const friendsDemo = [
  {
    name: "TM",
    image: "https://earthscene.co/wp-content/uploads/2024/03/tm.jpeg",
    activity: [{ type: "text", number: 3 }, { type: "post", number: 3 }]
  },
  {
    name: "MM",
    image: "https://earthscene.co/wp-content/uploads/2024/03/tm.jpeg",
    activity: [{ type: "text", number: 3 }, { type: "post", number: 3 }]
  },
  {
    name: "TM",
    image: "https://earthscene.co/wp-content/uploads/2024/03/tm.jpeg",
    activity: [{ type: "text", number: 3 }, { type: "post", number: 3 }]
  },
]

function Sidebar() {
  const navigate = useNavigate()
  const [friends, setFriends] = useState(friendsDemo)
  const { user, setUser } = useContext(AuthContext)
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const sidebarHeight = document.querySelector('.sidebar').clientHeight;
      if (sidebarHeight >= 711 && friends.length > 1) {
        // Reduce the number of friends displayed when the sidebar height exceeds 635px
        setFriends(prevFriends => prevFriends.slice(0, prevFriends.length - 1));
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [friends]); // Add friends to the dependency array

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <div className='w-[22%] h-[87.65vh] shadow-lg px-[20px] sidebar'>
      <div className='w-[100%] h-[43%] py-[15px] pb-[30px]'>
        {menu.map((item, index) => (
          <div
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              if (item.name === "Saved") {
                navigate("/saved")
              }
            }}
            className={`menuItemreal menuItem w-[100%] h-[20%] bg-white flex items-center rounded-[15px] mb-[5px] text-[18px] cursor-pointer text-[#a3a3a3] px-[15px] hover:text-[#476C6A]`}
          >
            <img
              src={hoveredItem === index ? item.iconFilled : item.iconOutlined}
              alt='Home'
              onClick={item.onClick}
              className='icon mr-[10px] transition-transform'
            />
            <p className='text'>{item.name}</p>
          </div>
        ))}
        <div className='h-[110%] w-[100%] mt-[25px] friends'>
          <div className='w-[100%] flex items-center justify-between mb-[10px]'>
            <h1 className='text text-[18px] font-bold'>My Friends</h1>
            <h1 className='text text-[#476C6A] cursor-pointer'>See all</h1>
          </div>
          <div className='w-[100%] h-[100%] friendContainer'>
            {friends.map((friend) => (
              <div className='friend w-[100%] h-[50px] mb-[10px] cursor-pointer flex items-center justify-between'>
                <div className='flex items-center'>
                  <img src={friend.image} className='friendimg w-[40px] h-[40px] rounded-full mr-[10px]' alt="" />
                  <h1 className='text-[18px] text'>{friend.name}</h1>
                </div>
                <div className='w-[10px] h-[10px] bg-red-500 rounded-full text'>
                
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='w-[100%] mt-[-20px]'>
          <div className='bottomMenu1 menuItem w-[100%] h-[50px] mb-[10px] flex items-center rounded-[15px] px-[10px]'>
            <img src={SettingsOutlined} className='w-[35px] h-[35px] settings mr-[8px]' alt='Settings' />
            <h1 className='text text-[#a3a3a3] text-[18px]'>Settings</h1>
          </div>
          {user ? (
            <div className='bottomMenu2  menuItem w-[100%] h-[50px] mb-[10px] flex items-center px-[10px]' onClick={() => {
              logout(navigate, "/signup")
              setUser(null)
            }}>
              <img src={LogoutOutlined} className='w-[30px] h-[30px] mr-[8px]' alt='Logout' />
              <h1 className='text text-[#a3a3a3] text-[18px]'>Logout</h1>
            </div>
          ) : (
              <div onClick={() => navigate("/Signup")} className='bottomMenu2 menuItem w-[100%] h-[50px] mb-[10px] rounded-[15px] flex items-center px-[10px]'>
                <h1 className='text-[#a3a3a3] text-[18px]'><GroupAddIcon /> <span className='text'>Sign up / Log in</span></h1>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;