import React, { useState, useContext, useEffect } from 'react'
import "./style.css"
import LocationIcon from "../../assets/icons/location.svg"
import Add from "../../assets/icons/Add.svg"
import Attachment from "../../assets/icons/Attachment.svg"
import Image from "../../assets/icons/Image.svg"
import { addDoc, collection, doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import Video from "../../assets/icons/Video.svg"
import Modal from '../Modal'
import { AuthContext } from '../../context/AuthContext'
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from "@reach/combobox";
import "@reach/combobox/styles.css";

const PostSection = () => {
  const [postText, setPostText] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [locationSelected, setLocationSelected] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState(""); // Initial coordinates
  const [selectedImages, setSelectedImages] = useState([])
  const { user } = useContext(AuthContext)
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const createPost = async (e) => {
    e.preventDefault();
  
    try {
      const postsCollectionRef = collection(db, 'posts');
  
      let postObject = {
        uid: user?.uid,
        username: user?.displayName,
        createdAt: serverTimestamp(),
        caption: postText,
        imageUrls: selectedImages,
        location: locationSelected ? value : "",
        photoURL: user?.photoURL,
        likes: 0,
        comments: [],
      };
  
      // Update the location field in postObject to be a string
      if (locationSelected) {
        postObject = { ...postObject, location: value };
      }
  
      // Add the post to Firestore
      const docRef = await addDoc(postsCollectionRef, postObject);
  
      console.log('Post document successfully added to Firestore!');
  
      // Update the document with the generated ID
      await updateDoc(doc(db, 'posts', docRef.id), { id: docRef.id });
  
      // Reset state values
      setSelectedImages("");
      setPostText("");
      setLocationSelected(false);
      setValue("");
      clearSuggestions();
    } catch (error) {
      console.error('Error adding post document to Firestore:', error);
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      createPost(event)
    }
  };

  return (
    <form onSubmit={handleKeyPress} className={`pt-[20px] ease-in-out duration-200 w-[95%] h-[120px] border-[#e2e2e2] rounded-[10px] border-[1px] mt-[20px] flex items-center justify-center flex-col ${!locationSelected ? "pb-[5px]" : "min-h-[180px]"}`}>
      <input 
        type="text" 
        placeholder='What do you want to publish?' 
        value={postText}
        onKeyDown={(e) => handleKeyPress(e)}
        onChange={(e) => setPostText(e.target.value)}
        className='outline-none border-b-[1px] border-[#e2e2e2] w-[95%] text-[#999] pb-[10px]' 
      />
      {locationSelected ? (
        <Combobox
        onSelect={(address) => {
          setValue(address, false);
          clearSuggestions();
        }}
        className='w-[100%] flex items-center justify-center'
      >
        <ComboboxInput
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={!ready}
          placeholder="Enter your location"
          className='outline-none border-[1px] border-[#e2e2e2] w-[95%] text-[#999] p-[10px] mt-[10px] rounded-[3px]'
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      ): null}
      <div className='w-[100%] h-[60px] flex items-center justify-between px-[20px]'>
        <div className='flex'>
          <div className='postIcon mr-[20px] cursor-pointer'>
            <img src={Add} alt='Add' className='w-[30px] h-[30px' />
          </div>
          <div className='postIcon mr-[20px] cursor-pointer'>
            <img src={Attachment} alt='Attachment' className='w-[30px] h-[30px]' />
          </div>
          <div className='postIcon mr-[20px] cursor-pointer relative' onClick={() => setIsOpen(true)}>
            <img src={Image} alt='Img' className='w-[30px] h-[30px]' />
            {selectedImages.length ? (
              <div className='top-[-5px] right-[-5px] absolute w-[16px] h-[16px] rounded-full bg-[#ef4444] text-[#fff] text-[12px] font-bold flex items-center justify-center'>{selectedImages.length}</div>
            ) : null}
          </div>
          <div className='postIcon mr-[10px] cursor-pointer' >
            <img src={Video} alt='Video' className='w-[30px] h-[30px]' />
          </div>
          <div className='postIcon mr-[10px] cursor-pointer' onClick={() => setLocationSelected(!locationSelected)}>
            <img src={LocationIcon} alt='Location' className='w-[33px] h-[33px]' />
          </div>
        </div>
        <div className='h-[100%] flex items-center justify-between'>
          <button className='w-[60px] mr-[10px] h-[30px] border-[#c6c6c6] border-[1px] rounded-[20px] text-[#c6c6c6]'>Draft</button>
          <button className='postButton w-[100px] h-[30px] bg-red-500 rounded-[20px] text-[#fff]' onClick={createPost}>Post Now</button>
        </div>
      </div>
      <Modal 
        isOpen={isOpen}
        modalType={"Image"}
        setIsOpen={setIsOpen}
        selectedImages={selectedImages}
        setSelectedImages={setSelectedImages}
      />
    </form>
  )
}

export default PostSection