import React, { useContext, useEffect } from 'react'
import { Main, Sidebar, Header } from '../../components'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

function Home() {
  return (
    <>
      <Header />
      <div className='w-screen flex'>
        <Sidebar />
        <Main />
      </div>
    </>
  )
}

export default Home