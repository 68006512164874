import React, { useState } from 'react'
import EmailIcon from "../../assets/icons/Email.svg";
import "./style.css"
import Lottie from 'react-lottie';
import Logo from "../../assets/images/EarthScene.png"
import PasswordIcon from "../../assets/icons/Password.svg";
import loadingAnimation from "../../assets/animations/loadinganimation.json"
import { auth } from '../../lib/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate("")

  const signIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      if (!email.trim() || !password.trim()) {
        setError("Please enter both email and password.");
        return;
      }

      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      setEmail("");
      setPassword("");

      navigate("/")
    } catch (error) {
      console.error('Error signing in:', error.message);
      setError('Invalid email or password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFocus = (index) => {
    console.log('Focus event triggered');
    const elements = document.getElementsByClassName('animated-border');
    for (let i = 0; i < elements.length; i++) {
        if (i === index) {
            elements[i].classList.add('focused');
        } else {
            elements[i].classList.remove('focused');
        }
    }
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

  return (
    <div className='w-screen h-screen flex bigboi'>
      <div className='w-[50%] h-screen bg-red-600'>
      <img src={require("../../assets/images/signup.jpg")} className='w-[100%] h-[100%] object-cover' />
      </div>
      <div className='w-[50%] h-[100%] pt-[60px] justify-center flex items-center'>
        <div className='absolute right-[42.5%] top-[50px]'>
          <img src={Logo} alt='Logo' className='w-[80px] h-[80px]' />
        </div>
      <form className={`w-[92%] h-[60%]`}>
      <div className='w-[100%] flex items-center justify-center mb-[3px]'>
        <h1 className='font-semibold text-[25px]'>Login to Earthscene</h1>
      </div>
      <div className='w-[100%] flex items-center justify-end mb-[10px]'>
        <p className='flex text-[15px] mt-[10px] text-[#999]'>Don't have an account? <span onClick={() => navigate("/signup")} className='text-[15px]' style={{ margin: "0 5px", cursor: "pointer", color:  "#ef4444" }}>Sign up</span></p>
      </div>
      {error && (
        <div className='w-[100%] h-[60px] bg-[#ffe6e6] text-[#e55c59] rounded-[5px] border-[1px] border-[#e55c59] mb-[15px] flex items-center px-[20px]'>
            {error}
        </div>
    )}
        <div className='w-[100%] h-[50px] rounded-full px-[15px] border-[#b1b1b1] border-[1px] outline-none flex items-center animated-border'>
          <img src={EmailIcon} className='w-[25px] h-[28px]' alt="EmailIcon" />
          <input
              type="text"
              placeholder='Email Address'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => handleFocus(2)}
              className='w-[100%] h-[100%] outline-none border-none bg-transparent ml-[10px] text-[#999]'
          />
        </div>
        <div className='w-[100%] h-[50px] rounded-full px-[15px] border-[#b1b1b1] border-[1px] outline-none flex items-center mt-[20px] animated-border'>
          <img src={PasswordIcon} className='w-[25px] h-[28px]' alt="PasswordIcon" />
          <input
              type="password"
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => handleFocus(3)}
              autoComplete="current-password"
              className='w-[100%] h-[100%] outline-none border-none bg-transparent ml-[10px] text-[#999]'
          />
        </div>
        <div className='w-[100%] h-[45px] flex items-center justify-center mt-[26px]'>
        <button 
          className={`flex items-center justify-center w-[40%] h-[100%] bg-[#476C6A] text-[#fff] rounded-full font-bold button-animation ${!email || !password && `bg-[#5d8886]`}`}
          disabled={loading}
          onClick={(e) => {
              signIn(e)
          }}>
          {!loading ? (
              <>
                  <p className='font-bold'>Login</p>
              </>
          ) : (
              <Lottie options={defaultOptions} height={50} width={70} />
          )}
        </button>
        </div>
      </form>
      </div>
      </div>
  )
}

export default Login