import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import "./style.css"
import { collection, doc, getDoc, getDocs, query, updateDoc, where, writeBatch } from 'firebase/firestore'
import { db } from '../../lib/firebase'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const ProfileView = ({ user }) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
  
      const response = await axios.post(
        'https://earthscene.co/wp-json/wp/v2/media',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
  
      const imageUrl = response.data.source_url;
      setSelectedImage(imageUrl);
    } catch (error) {
      console.error('Error uploading file:', error);
  
      // Log more details for debugging
      console.error('Response status:', error.response?.status);
      console.error('Response data:', error.response?.data);
    }
  }, [user?.token]);

  const [photoURL, setPhotoURL] = useState(user?.photoURL);
  const [selectedImage, setSelectedImage] = useState('');
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [displayName, setDisplayName] = useState(user?.displayName);
  const [gender, setGender] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate("")

  useEffect(() => {
    const getData = async () => {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const genderValue = userDocSnapshot.data().gender; // Extract gender field
          const nameValue = userDocSnapshot.data().displayName; // Extract gender field
          const profilePic = userDocSnapshot.data().photoURL
          setGender(genderValue); // Update state with gender value
          setDisplayName(nameValue)
          setPhotoURL(profilePic)
        } else {
          console.log('User document does not exist');
        }
      } catch (error) {
        console.error('Error getting user document:', error);
      }
    };

    getData();
  }, [user?.uid]); // Add user.uid to dependency array

  const handleDisplayNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 15) {
      setDisplayName(value);
      setErrorMessage('');
    } else {
      setErrorMessage('Username cannot be longer than 15 characters');
    }
  };

  console.log(selectedImage)

  useEffect(() => {
    if (selectedImage) {
      setPhotoURL(selectedImage)
      console.log("TM")
    }
  }, [selectedImage])

  const save = async () => {
    try {
      // Update user profile
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, {
        photoURL: photoURL,
        displayName: displayName,
        gender: gender
      });

      console.log('Profile and associated posts updated successfully!');

      await navigate("/");
      await window.location.reload();
    } catch (error) {
      console.error('Error updating profile and associated posts:', error);
    }
  }

  return (
    <div className='w-[80%] h-[100%] flex pt-[20px] px-[20px]'>
      <div className='w-[80%]'>
        <p className='mt-[0px] text-[15px] text-[#858585]'>Username</p>
        <input
          className='border-[1px] border-[#c7c7c7] w-[100%] p-[10px] rounded-[10px] mt-[5px] mb-[15px] text-[#858585]'
          value={displayName}
          onChange={handleDisplayNameChange}
        />
        {errorMessage && <p className="text-red-600 mt-[5px]">{errorMessage}</p>}
        <div {...getRootProps()} className={`flex items-center justify-center w-[100%] h-[26%] border-[2px] border-[#c3c3c3] mt-[10px] border-dashed rounded-[12px] ${isDragActive ? "border-[1px] border-[#517876] " : ""}`}>
         <input {...getInputProps()} type="text" />
          <h1 className='text-[17px] text-[#878787]'>
            {selectedImage ? (
              `You're currently selecting: ${selectedImage.split('/').pop()}`
            ) : (
              isDragActive ? 'Drop the files here!' : 'Drag and drop your profile picture or upload it'
            )}
          </h1>
        </div>
        <p className='mt-[15px] text-[15px] text-[#858585]'>Gender/pronouns</p>
        <input
          placeholder=''
          className='border-[1px] border-[#c7c7c7] w-[100%] p-[10px] rounded-[10px] mt-[5px] text-[#858585]'
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        />
        <button onClick={save} className='savebtn w-[200px] h-[45px] mt-[15px] cursor-pointer bg-red-600 hover:bg-red-500 flex items-center justify-center rounded-[10px] text-[#fff] font-semibold text-[16px]'>Save</button>
      </div>
      <div className='w-[20%] flex flex-col items-center py-[20px]'>
        <img 
          key={photoURL} // Add key prop here
          src={photoURL} 
          className='mb-[5px] w-[150px] h-[150px] rounded-full object-cover' 
          alt="" 
        />
        <div className='text-[23px] text-[#858585] font-normal mb-[-5px]'>{displayName}</div>
        <p className='text-[#adadad]'>{gender}</p>
      </div>
    </div>
  )
}

export default ProfileView