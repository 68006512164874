import React, { useCallback, useContext, useRef } from 'react'
import "./style.css"
import {useDropzone} from 'react-dropzone'
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import ModalComponent from 'react-modal';
import { AuthContext } from '../../context/AuthContext';
import axios from "axios"

const Modal = ({ isOpen, setIsOpen, selectedImages, setSelectedImages }) => {
    const { user } = useContext(AuthContext)
    const inputRef = useRef(null); // Create a reference for the input element
    const onDrop = useCallback(async (acceptedFiles) => {
      try {
        const uploadedImages = [];
    
        // Loop through each accepted file and upload it
        for (const file of acceptedFiles) {
          const formData = new FormData();
          formData.append('file', file);
    
          const response = await axios.post(
            'https://earthscene.co/wp-json/wp/v2/media',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
    
          if (!response.data || !response.data.source_url) {
            console.error('Error: Invalid response format.');
            continue; // Skip this iteration and proceed with the next file
          }
    
          const imageUrl = response.data.source_url;
          uploadedImages.push({ name: file.name, url: imageUrl });
        }
    
        // Update the selectedImages array with the new objects
        setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...uploadedImages]);
      } catch (error) {
        console.error('Error uploading file:', error);
    
        // Log more details for debugging
        console.error('Response status:', error.response?.status);
        console.error('Response data:', error.response?.data);
      }
    }, [user?.token, setSelectedImages]);
    

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    function closeModal() {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          outline: "none", // Remove default focus outline
          position: "absolute",
          transform: "translate(-50%, -50%)",
          maxWidth: "800px", // Adjust the max width as needed
          maxHeight: "70%", // Adjust the max height as needed
          borderRadius: 10,
        },
      };      

  return (
    <ModalComponent
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        className="w-[600px] h-[70%] border-[1px] bg-white "
    >
        <div className='w-[100%] h-[80px] flex items-center px-[20px] text-[30px] font-semibold tl-tr-[10px] bl-none text-[#000] mt-[px] border-b-[1px]'>
            <h1 className='flex-1'>Upload an Image</h1>
            <div onClick={() => setIsOpen(false)}>
                <CloseIcon style={{ fontSize: 35, color: "#888", cursor: "pointer" }} />
            </div>
        </div> 
        <div className='w-[100%] h-[70%] flex items-center justify-center flex-col' {...getRootProps()}>
        <input {...getInputProps()} ref={inputRef} style={{ display: 'none' }} /> {/* Hidden input element */}
            <div className='UploadContainer w-[200px] h-[200px] bg-[#eee] rounded-full flex items-center justify-center flex-col mb-[10px]'>
                <UploadIcon style={{ fontSize: 135, color: "#bbb" }} className={`${!isDragActive ? "UploadIcon" : "UploadIconActive"}`} />
            </div>
            <p className='text-[16px] text-[#999]'>Add Your Own Photos or Drag and Drop them</p>
            <p className='mt-[5px] text-[#bbb]'>
            {!selectedImages?.length
              ? null
              : selectedImages.length === 1
              ? `You're currently selecting: ${selectedImages[0].name}`
              : `You're currently selecting: ${selectedImages.map(file => file.name).join(', ')}`
            }
          </p>
            <button onClick={() => setIsOpen(false)} className='absolute bottom-[35px] bg-[#ef4444] w-[90%] h-[50px] rounded-full font-bold text-[#fff]'>Done</button>  
        </div>
    </ModalComponent>  
  )
}

export default Modal