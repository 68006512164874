import axios from 'axios';
import React, { createContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../lib/firebase';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userBackgroundImage, setUserBackgroundImage] = useState('');
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          const userDocRef = doc(db, 'users', authUser.uid);
          const docSnapshot = await getDoc(userDocRef);
          const fieldValue = docSnapshot.data();
          const tokenValue = fieldValue?.token || "";
          const photoURLFromDB = fieldValue?.photoURL || ""; // Get photoURL from the database
          
          const userNameDocRef = doc(db, 'users', authUser.uid);
          const snapshot = await getDoc(userNameDocRef);
          
          if (snapshot.exists()) {
            const value = snapshot.data();
            const displayName = value?.displayName || "";
          
            setUser({
              token: tokenValue,
              photoURL: photoURLFromDB || authUser.photoURL, // Use photoURL from the database, fallback to authUser.photoURL
              email: authUser.email,
              displayName: displayName,
              uid: authUser.uid,
            });
          } else {
            console.log("User document not found");
          }

        } catch (error) {
          console.error("Firestore error", error);
        }
      } else {
        setUser(null); // Set user state to null or empty
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // This useEffect will run after the one above, ensuring setUser has been set
    if (user !== null && user.hasOwnProperty('token')) {
      // Additional logic when user is not null and has 'token' property
      console.log("User is not null:", user);
      console.log("User token:", user.token);

      // You can set 'token' state here if needed
      setToken(user.token);
    }
  }, [user]);

  const createUser = async (email, displayName, password, fullname) => {
    // Your existing createUser logic
    // ...

    // Example of additional logic after user is created
    if (user !== null && user.hasOwnProperty('token')) {
      // Do something with the user and 'token'
      console.log("User created:", user);
      console.log("User token:", user.token);

      // You can set 'token' state here if needed
      setToken(user.token);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userBackgroundImage,
        setUserBackgroundImage,
        user,
        setUser,
        token,
        setToken,
        createUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
