import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD-a5WS2s7Mm-SY_le_Um-hB0lPMeGjVdc",
  authDomain: "earthscene-a6123.firebaseapp.com",
  projectId: "earthscene-a6123",
  storageBucket: "earthscene-a6123.appspot.com",
  messagingSenderId: "1095248718836",
  appId: "1:1095248718836:web:fbe6cf72e20c01ce1727a1",
  measurementId: "G-PFGJH6BBFJ"
};

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp)

export { db, auth }
