import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Header, ProfileSidebar, ProfileView } from '../../components';
import { AuthContext } from '../../context/AuthContext';

const Profile = () => {
  const [selectedItem, setSelectedItem] = useState("profile")
  const { user } = useContext(AuthContext)

  return (
    <div>
        <Header />
        <div className='flex h-[87.65vh]'>
            <ProfileSidebar
               selectedItem={selectedItem}
               setSelectedItem={setSelectedItem}
            />
            {selectedItem === "profile" && (
              <ProfileView 
                user={user}
              />
            )}
        </div>
    </div>
  )
}

export default Profile