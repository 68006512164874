import { updateProfile } from "firebase/auth"
import { auth, db } from "../lib/firebase"
import { doc, updateDoc } from "firebase/firestore";

export const updateUserInFirebase = async (displayName, bio, gender, selectedDate, photoURL, uid) => {
    await updateProfile(auth.currentUser, {
        displayName: displayName,
        photoURL: photoURL ? photoURL : `https://api.dicebear.com/7.x/initials/svg?seed=${displayName}`
    })

    const fieldsToUpdate = {
        displayName: displayName,
        bio: bio,
        photoURL: photoURL ? photoURL : `https://api.dicebear.com/7.x/initials/svg?seed=${displayName}`,
        dateOfBirth: selectedDate,
        gender: gender,
    };
      
    const userDocRef = await doc(db, 'users', uid);

    try {
        await updateDoc(userDocRef, fieldsToUpdate);
        console.log('Document successfully updated!');

        return {
            type: "success",
            message: "updated"
        }
    } catch (error) {
        console.error('Error updating document:', error);

        return {
            type: "error",
            message: error
        }
    }
}