import React, { useContext, useEffect, useState } from 'react';
import './style.css';
import Post from '../Post';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import Header from '../Header';

const Feed = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const collectionRef = collection(db, 'posts'); // Replace with your actual collection name
  
    const q = query(collectionRef, orderBy('createdAt', 'desc')); // Order posts by timestamp in descending order
  
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newPosts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(newPosts);
    });
  
    return () => unsubscribe(); // Cleanup the listener when the component unmounts
  }, []);

  return (
    <div className='w-[100%] mt-[10px] flex items-center flex-col'>
      {posts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
    </div>
  );
};

export default Feed;
