import React, { useEffect } from 'react'
import FollowButton from '../FollowButton'
import { useNavigate } from 'react-router-dom'
import PostSection from '../PostSection'
import Feed from '../Feed'

const profiles = [
  {
    image: "https://media4.giphy.com/media/ltIFdjNAasOwVvKhvx/200w.gif?cid=6c09b952b0pgis8k2yhvsvv22fe4dboyzbdg3mhssihwmn0w&ep=v1_gifs_search&rid=200w.gif&ct=g",
    name: "Hello",
    followers: "90.3K",
    id: "893898",
    followed: true
  },
  {
    image: "https://media3.giphy.com/media/YWf50NNii3r4k/giphy.gif?cid=6c09b95289gp328p85nnhl585shxyizwk809qkdh2tkchr24&ep=v1_gifs_search&rid=giphy.gif&ct=g",
    name: "meme",
    followers: "90.3K",
    id: "893898",
    followed: false
  },
  {
    image: "https://media4.giphy.com/media/ltIFdjNAasOwVvKhvx/200w.gif?cid=6c09b952b0pgis8k2yhvsvv22fe4dboyzbdg3mhssihwmn0w&ep=v1_gifs_search&rid=200w.gif&ct=g",
    name: "Hello",
    followers: "90.3K",
    id: "893898",
    followed: true
  },
  {
    image: "https://media3.giphy.com/media/YWf50NNii3r4k/giphy.gif?cid=6c09b95289gp328p85nnhl585shxyizwk809qkdh2tkchr24&ep=v1_gifs_search&rid=giphy.gif&ct=g",
    name: "meme",
    followers: "90.3K",
    id: "893898",
    followed: true
  },
  {
    image: "https://media4.giphy.com/media/ltIFdjNAasOwVvKhvx/200w.gif?cid=6c09b952b0pgis8k2yhvsvv22fe4dboyzbdg3mhssihwmn0w&ep=v1_gifs_search&rid=200w.gif&ct=g",
    name: "Hello",
    followers: "90.3K",
    id: "893898",
    followed: true
  },
  {
    image: "https://media3.giphy.com/media/YWf50NNii3r4k/giphy.gif?cid=6c09b95289gp328p85nnhl585shxyizwk809qkdh2tkchr24&ep=v1_gifs_search&rid=giphy.gif&ct=g",
    name: "meme",
    followers: "90.3K",
    id: "893898",
    followed: false
  },
  {
    image: "https://media4.giphy.com/media/ltIFdjNAasOwVvKhvx/200w.gif?cid=6c09b952b0pgis8k2yhvsvv22fe4dboyzbdg3mhssihwmn0w&ep=v1_gifs_search&rid=200w.gif&ct=g",
    name: "Hello",
    followers: "90.3K",
    id: "893898",
    followed: true
  },
  {
    image: "https://media3.giphy.com/media/YWf50NNii3r4k/giphy.gif?cid=6c09b95289gp328p85nnhl585shxyizwk809qkdh2tkchr24&ep=v1_gifs_search&rid=giphy.gif&ct=g",
    name: "meme",
    followers: "90.3K",
    id: "893898",
    followed: true
  },
]

function Main() {
  const navigate = useNavigate("")

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.botpress.cloud/webchat/v1/inject.js';
    script.async = true;
    script.onload = () => {
  window.botpressWebChat.init({
      "composerPlaceholder": "I'm all ears, tell me what's up!",
      "botId": "28ced8e6-9a2c-414b-b35b-0fb87ea7c82e",
      "hostUrl": "https://cdn.botpress.cloud/webchat/v1",
      "messagingUrl": "https://messaging.botpress.cloud",
      "clientId": "28ced8e6-9a2c-414b-b35b-0fb87ea7c82e",
      "webhookId": "afcbf7bd-f588-4ada-9dcf-cf11d97abff6",
      "lazySocket": true,
      "themeName": "prism",
      "frontendVersion": "v1",
      "showPoweredBy": true,
      "theme": "prism",
      "themeColor": "#cc3333"
  });
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div className='w-[100%] h-[87.85vh] flex'>
      <div className='w-[80%] flex items-center flex-col overflow-scroll px-[20px]'>
        <PostSection />
        <Feed />
      </div>
      <div className='rightpart w-[33%] flex justify-center py-[20px] flex-col'>
        <div className='w-[95%] h-[53%] border-[#e2e2e2] border-[1.5px] rounded-md px-[20px] overflow-scroll'>
          <div className='w-[100%] h-[70px] border-b-[1.5px] flex items-center justify-between'>
            <h1 className='text-[20px] text-[#000]'>Popular Profiles</h1>
            <p className='text-red-600 cursor-pointer'>See all</p>
          </div>
          {profiles.map((profile) => (
              <div className='w-[100%] h-[60px] flex items-center my-[7px] justify-between cursor-pointer' onClick={() => navigate(`/profile/${profile.id}`)}>
              <div className='w-[100%] h-[60px] flex items-center mt-[5px]'>
                <img src={profile.image} className='w-[55px] h-[55px] rounded-full' alt="profile" />
                <div className='ml-[5px]'>
                  <h1 className='text-[20px]'>{profile.name}</h1>
                  <p className='text-[#cecece]'>{profile.followers} followers</p>
                </div>
              </div>
              <FollowButton
                text={profile.followed ? "Unfollow" : "Follow"}
                primaryColor={"#e53e3e"}
                followed={profile.followed}
              />
            </div>
            ))}
        </div>
        <div className='w-[95%] h-[53%] border-[#e2e2e2] border-[1.5px] rounded-md px-[20px] mt-[20px]'>
          <div className='w-[100%] h-[70px] border-b-[1.5px] flex items-center justify-between'>
            <h1 className='text-[20px] text-[#000]'>Trending Posts</h1>
            <p className='text-red-600 cursor-pointer'>See all</p>
          </div>
        </div>
        <div id="webchat" />
      </div>
    </div>
  )
}

export default Main