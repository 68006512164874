import React, { useContext, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/Flag';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import loadingAnimation from "../../assets/animations/loadinganimation.json"
import EditIcon from '@mui/icons-material/Edit';
import SavedFilled from "../../assets/icons/Saved-filled.svg"
import SavedOutlined from "../../assets/icons/Saved-outlined.svg"
import ThumbsdownOutlined from "../../assets/icons/Thumbsdown-outlined.svg"
import ThumbsupIconFilled from "../../assets/icons/thumbsup-filled.svg"
import ThumbsupIconOutlined from "../../assets/icons/thumbsup-outlined.svg"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CommentIcon from "../../assets/icons/comment.svg"
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SendIcon from '@mui/icons-material/Send';
import TrashIcon from "../../assets/icons/trash-open.svg"
import LanguageIcon from '@mui/icons-material/Language';
import { useSpring, animated } from 'react-spring';
import './style.css';
//icons
import Location from "../../assets/icons/location.svg"
import FollowIcon from "../../assets/icons/Follow.svg"
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from "../../assets/images/x-logo.png"
import InstagramIcon from "../../assets/images/instagram.svg"
import ReportIcon from "../../assets/icons/Report.svg"
import BlockIcon from "../../assets/icons/Block.svg"
import { AuthContext } from '../../context/AuthContext';
import { arrayRemove, arrayUnion, deleteDoc, doc, getDoc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const Post = ({ post }) => {
  const [editing, setEditing] = useState(false)
  const [editDone, setEditDone] = useState(true)
  const [showHoverElement, setShowHoverElement] = useState(false);
  const [replies, setReplies] = useState([])
  const [saved, setSaved] = useState(false);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, right: 0 });
  const hoverRef = useRef(null);
  const [confirmed, setConfirmed] = useState()
  const [like, setLike] = useState(false)
  const [location, setLocation] = useState("")
  const [showMore, setShowMore] = useState(false)
  const [commentModalVisible, setCommentModalVisible] = useState(false)
  const [editOpen, setEditOpen] = useState(false);
  const [comment, setComment] = useState("")
  const { user } = useContext(AuthContext)
  const containerRef = useRef(null);
  const [liked, setLiked] = useState(false);
  const [showComments, setShowComments] = useState(false)
  const [loading, setLoading] = useState(false)
  const [likes, setLikes] = useState(0);
  const menuRef = useRef(null);
  const [editMessage, setEditMessage] = useState(post.caption)
  const [modalVisible, setModalVisible] = useState(false)

  

  useEffect(() => {
    const checkLikedStatus = async () => {
      try {
        const userDocRef = doc(db, 'users', user?.uid);
  
        const userDoc = await getDoc(userDocRef);
  
        if (userDoc.exists()) {
          const likedPosts = userDoc.data()?.likedPosts || [];
          setLiked(likedPosts.includes(post.id));
        } else {
          console.log('User document does not exist');
        }
      } catch (error) {
        console.error('Error fetching user document:', error);
      }
    };
  
    checkLikedStatus();
  }, [post.id, user?.uid]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setEditOpen(false);
      }
    };

    if (editOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [editOpen]);

  const postDocRef = doc(db, 'posts', post?.id);
  const userDocRef = user?.uid ? doc(db, 'users', user.uid) : null;

  const handleLike = async () => {
    setLiked(!liked)

    try {
      if (!liked) {
        await updateDoc(userDocRef, {
          likedPosts: arrayUnion(post.id),
        });

        const postDoc = await getDoc(postDocRef);
        const currentLikes = postDoc.data().likes || 0;
    
        const updatedLikes = currentLikes + 1;
        await updateDoc(postDocRef, { likes: updatedLikes });
        await setLike(!like)
      } else {
        const postDoc = await getDoc(postDocRef);
        const currentLikes = postDoc.data().likes || 0;

        await updateDoc(userDocRef, {
          likedPosts: arrayRemove(post.id),
        });
    
        const updatedLikes = currentLikes - 1;
        await updateDoc(postDocRef, { likes: updatedLikes });
        await setLike(!like)
      }
    } catch (error) {
      console.error('Error incrementing likes:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(postDocRef, (doc) => {
      if (doc.exists()) {
        const updatedLikes = doc.data().likes || 0;
    
        setLikes(updatedLikes);
      }

      return () => unsubscribe();
    });
  }, [])

  const handleComment = async () => {
    try {
      const postDocRef = doc(db, 'posts', post.id);
  
      const commentData = {
        userId: user.uid,
        displayName: user.displayName,
        photoURL: user.photoURL,
        textContent: comment,
        likes: 0,
        dislikes: 0,
        replies: [],
        timestamp: new Date().toISOString(),
      };

      await updateDoc(postDocRef, {
        comments: arrayUnion(commentData),
      });
  
      setComment("")
      console.log('Comment added successfully!');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  }

  useEffect(() => {
    const postDocRef = doc(db, 'posts', post.id);
  
    const unsubscribe = onSnapshot(postDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const postData = docSnapshot.data().comments;
  
        postData?.sort((a, b) => b.timestamp - a.timestamp);
  
        setReplies(postData);
      } else {
        console.log('Post does not exist');
      }
    });
  
    return unsubscribe;
  }, [replies]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const docSnapshot = await getDoc(userDocRef);
        const savedPosts = docSnapshot.data().saved || [];
        setSaved(savedPosts.includes(post.id));
      }
    };

    fetchUserData();
  }, [user, post.id]);

  useEffect(() => {
    const getLocationByPostId = async (postId) => {
      try {
        // Create a reference to the post document
        const postDocRef = doc(db, 'posts', postId);
    
        const docSnapshot = await getDoc(postDocRef);
    
        if (docSnapshot.exists()) {
          const docLocation = docSnapshot.data().location;
          setLocation(docLocation)
        } else {
          console.log('Post document does not exist');
          setLocation("")
          return null;
        }
      } catch (error) {
        console.error('Error fetching post document:', error);
        return null;
      }
    };

    getLocationByPostId(post.id)
  }, [post.id])

  const ImageCarousel = ({ imageUrls }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    const handleNext = () => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const handlePrev = () => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
      );
    };
  
    const transition = useSpring({
      opacity: 1,
      from: { opacity: 0 },
      reset: true,
    });
  
    const handleThumbnailClick = (index) => {
      setCurrentImageIndex(index);
    };
  
    return (
      <div className='relative flex items-center justify-center flex-col'>
        <animated.button
          onClick={handlePrev}
          className='absolute left-0 top-1/2 transform -translate-y-1/2'
          style={{ ...transition }}
        >
          <NavigateNextIcon style={{ fontSize: 35, transform: 'scaleX(-1)', color: "#888" }} />
        </animated.button>
        <animated.img
          src={imageUrls[currentImageIndex].url}
          alt={`post-image-${currentImageIndex}`}
          className='w-[26%] mb-[10px] object-contain'
          key={currentImageIndex}
          style={{ ...transition }}
        />
        <animated.button
          onClick={handleNext}
          className='absolute right-0 top-1/2 transform -translate-y-1/2'
          style={{ ...transition }}
        >
          <NavigateNextIcon style={{ fontSize: 35, color: "#888" }} />
        </animated.button>
  
        {/* Dots */}
        <div className='flex mt-4'>
          {imageUrls.map((imageUrl, index) => (
            <div
              key={index}
              className={`w-4 h-4 mx-1 cursor-pointer rounded-full ${
                index === currentImageIndex ? 'bg-red-500' : 'bg-gray-300'
              }`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!showHoverElement) {
      setShowMore(false)
    }
  }, [showHoverElement])

  const handleHover = (event) => {
    const hoverTop = event.currentTarget.getBoundingClientRect().top + window.scrollY;
    const hoverRight = event.currentTarget.getBoundingClientRect().right + 90;

    setHoverPosition({ top: hoverTop, right: hoverRight });
    setShowHoverElement(true);
  };

  const handleLeave = () => {
    setShowHoverElement(false);
  };

  const handleHoverElementLeave = () => {
    setShowHoverElement(false);
  };

  const handleHoverElementEnter = () => {
    setShowHoverElement(true);
  };


  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const docSnapshot = await getDoc(userDocRef);
        const savedPosts = docSnapshot.data().saved || [];
        setSaved(savedPosts.includes(post.id));
      }
    };

    fetchUserData();
  }, [user, post.id]);


  const updateSavedPosts = async (userId, postId) => {
    const userDocRef = doc(db, 'users', userId);
  
    try {
      const docSnapshot = await getDoc(userDocRef);
      const savedPosts = docSnapshot.data().saved || [];
  
      // Check if the post is already saved
      const index = savedPosts.indexOf(postId);
      if (index === -1) {
        // If not saved, add post ID to the array
        savedPosts.push(postId);
      } else {
        // If saved, remove post ID from the array
        savedPosts.splice(index, 1);
      }
  
      // Update the user document with the modified saved array
      await updateDoc(userDocRef, { saved: savedPosts });
  
      console.log('User document successfully updated!');
    } catch (error) {
      console.error('Error updating user document:', error);
    }
  };

  const handleSaveClick = () => {
    setSaved(!saved);
    updateSavedPosts(user.uid, post.id); // Update Firestore document
  };

  function formatLikes(likes) {
    if (likes < 1000) {
      return likes.toString();
    } else if (likes < 1000000) {
      return (likes / 1000).toFixed(1) + 'k';
    } else if (likes < 1000000000) {
      return (likes / 1000000).toFixed(1) + 'M';
    } else {
      return (likes / 1000000000).toFixed(1) + 'B';
    }
  }
  
  const formattedLikes = formatLikes(likes);

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (editOpen && menuRef.current && !menuRef.current.contains(event.target)) {
        setEditOpen(false);
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [editOpen, setEditOpen]);

  const editPost = () => {
    setEditing(true)
    setEditDone(false)
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  const renderCaptionWithLinks = () => {
    const words = post.caption.split(/(\s+)/);
    const captionWithLinks = words.map((word, index) => {
      if (word.match(/^(https?:\/\/|www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/)) {
        const url = word.startsWith('www.') ? 'http://' + word : word;
        return (
          <a
            key={index}
            href={url.includes('://') ? url : 'http://' + url}
            target="_blank"
            rel="noopener noreferrer"
            className="blue-link"
          >
            {word}
          </a>
        );
      }
      return word;
    });

    return <p className='break-all'>{captionWithLinks}</p>;
  };

  const formatDate = (reply) => {
    const formattedDate = new Date(reply.timestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    return formattedDate
  }

  const confirmEditPost = async () => {
    const docRef = doc(db, 'posts', post.id);

    try {
      await updateDoc(docRef, {
        caption: editMessage,
      });

      await setEditing(false)
    } catch (error) {
      console.error('Error updating document:', error);
    }
  }

  const deletePost = async () => {
    const docRef = doc(db, 'posts', post.id);

    await setModalVisible(true)

    if (confirmed) {
      try {
        await deleteDoc(docRef);

        await setModalVisible(false)
      } catch (error) {
        console.error('Error deleting document:', error);
      } finally {
        setLoading(false)
      }
    } else {
      setEditOpen(false)
    }
  }

  useEffect(() => {
    setEditOpen(false)
  }, [editing])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      handleComment();
    }
  };

  return (
    <div
      className='container w-[95%] py-[10px] border-b-[#ebebeb] border-b-[1.3px]'
      key={post.id}
    >
      <div
        className='w-[100%] h-[50px] rounded-full flex items-center mb-[10px] cursor-pointer'
      >
        <img
          src={post.photoURL}
          alt='authorimage'
          className='rounded-full w-[40px] h-[40px] object-cover'
        />
      <div 
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
      className='flex flex-col ml-[10px] flex-1'>
        <h1 className='text-[20px] mb-[-5px]'>{post.username}</h1>
        <h5 className='text-[#c9c9c9] text-[13px]'>@{post.username}</h5>
      </div>
      <div>
        <div onClick={() => setEditOpen(!editOpen)}>
          <MoreHorizIcon
            style={{ color: "#888" }}
          />
        </div>
          {editOpen ? (
            <div className='w-[200px] h-fit bg-white absolute rounded-[10px] shadow-xl cursor-default justify-center flex-col'>
            {user.uid === post.uid ? (
              <div>
              <div className='postOption w-[100%] py-[10px] px-[10px] h-[50%] flex items-center border-b-[1px] cursor-pointer' onClick={() => setEditing(true)}><EditIcon style={{ fontSize: 20, marginRight: 3, color: "#292929"}} /> Edit Post</div>
              <div className='postOption text-[#cc3333] w-[100%] py-[10px] px-[10px] h-[50%] flex items-center border-b-[1px]' onClick={deletePost}><DeleteIcon style={{ color: "#cc3333", marginRight: 3}} /> <p className="goodText">Delete Post</p></div>
              </div>
            ) : null}
            <div className='postOption w-[100%] py-[10px] px-[10px] font-bold h-[50%] flex items-center border-b-[1px] text-red-600' >
              <FlagIcon style={{ color: "#cc3333", marginRight: 3}} />
              <p className="goodText">Report</p>
            </div>
            </div>
          ) : null}
      </div>
      </div>
      
      {post.imageUrls && post.imageUrls.length > 0 && (
        post.imageUrls.length === 1 ? (
          <div className='flex items-center justify-center'>
            <img
              src={post.imageUrls[0].url}
              alt={`post-image-0`}
              className='w-[20%] max-h-[200px] mb-[10px] object-contain'
            />
          </div>
        ) : (
          <ImageCarousel imageUrls={post.imageUrls} />
        )
      )}

      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        contentLabel="Example Modal"
        style={{
          content: {
            width: '50%', // Set the desired width here
            height: '70%', // Set the desired height here
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
      <div className='w-[100%] h-[75%] flex items-center justify-center px-[30%]'>
        <div className='bg-[#ffdede] flex items-center justify-center w-[300px] h-[300px] rounded-[300px]'>
        {loading ? (
          <Lottie options={defaultOptions} height={50} width={70} />
        ) : (
            <img 
              src={TrashIcon}
              alt="trashIcon"
              className='w-[150px] m-[20px]'
            />
        )}
        </div>
      </div>
      <div className='w-[100%] flex items-center justify-center mb-[10px]'>
        <h1 className='areyousureText'>Are you sure?</h1>
      </div>
        <div className='w-[100%] h-[60px] flex items-center justify-center'>
          <button className='cancelButton' onClick={() => setModalVisible(false)}>Cancel</button>
          <button className='confirmButton ml-[20px] rounded-[5px] w-[160px] h-[45px] bg-[#ffdede] text-red-600 font-bold' onClick={() => {
            setConfirmed(true) 
            deletePost()}}>Confirm</button>
        </div>
      </Modal>
      <div className='w-[100%] mt-[10px] flex items-center justify-between'>
      {!editing ? (
        <p>{renderCaptionWithLinks()}</p>
      ) : (
        <div className="w-[100%] flex items-center ">
          <input 
            value={editMessage}
            className="border-b-[#d1d1d1] pb-[5px] border-b-[1px] focus:border-b-[#8c8c8c] border-t-none w-[100%]"
            onChange={(e) => setEditMessage(e.target.value)}
          />
          <div className="flex itemx-center">
            <CloseIcon onClick={() => setEditing(false)}className='confirmIcon' style={{ marginRight: 5, color: "#ff2424", fontSize: 25, marginLeft: 20, cursor: "pointer"}} />
            <CheckIcon onClick={confirmEditPost} className='confirmIcon' style={{ marginRight: 10, color: "#000000", marginLeft: 5, cursor: "pointer"}} />
          </div>
        </div>
      )}
        {location && (
          <div className='flex items-center text-[#999] text-[14px]'><LocationOnIcon style={{ color: "#999", fontSize: 17, marginRight: 0}} />{location}</div>
        )}
      </div>
      <div className='w-[100%] flex items-center'>
        <div className='flex items-center my-[10px] flex-1'>
            <div onClick={handleLike} className='cursor-pointer flex items-center'>
            {liked ? (
              <img src={ThumbsupIconFilled} alt="thumbsfilled" className='w-[30px] h-[30px]' />
            ) : (
              <img src={ThumbsupIconOutlined} alt="thumbsOutline" className='w-[30px] h-[30px]' />
            )}
            <p className='ml-[5px] fivehundred text-[18px] text-[#888]'>{formattedLikes}</p>
            </div>
            <div onClick={() => setShowComments(!showComments)} className='cursor-pointer'>
              <img src={CommentIcon} alt='Comment' className='w-[30px] h-[30px] ml-[15px]' />
            </div>
        </div>
        <div className='cursor-pointer' onClick={handleSaveClick}>
          {saved ? (
            <img src={SavedFilled} alt="saved" className='w-[30px] h-[30px]' />
          ) : (
            <img src={SavedOutlined} alt="not saved" className='w-[30px] h-[30px]' />
          )}
        </div>
      </div>
      {showComments && (
        <div className='w-[100%] h-[180px] mt-[15px] pb-[10px]'>
            <input 
              placeholder='Leave a Comment'
              className='flex-1 w-[100%] border-b-[1px] pb-[5px]'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div className='w-[100%] flex items-center justify-end mt-[10px]'>
              <button onClick={() => setShowComments(false)} className='cancelBtn mr-[15px] p-[5px] px-[15px] rounded-full text-[#888]'>Cancel</button>
        <button
          onClick={() => handleComment()}  // Add this line to bind the function
          className='commentButton mr-[0px] p-[5px] px-[26px] rounded-full border-[#c6c6c6] border-[0px] bg-red-500 text-[#fff]'>
          Comment
        </button>
            </div>
            {replies.length > 0 && (
            <div>
              <div className='flex items-center'>
                <img 
                  src={replies[0]?.photoURL}
                  className='w-[35px] h-[35px] mr-[7px] rounded-full object-cover'
                />
                <p className='text-[20px] font-semibold'>{replies[0]?.displayName}</p>
              </div>
              <p className="mt-[5px]">{replies[0]?.textContent}</p>
              <div className='w-[100%] mt-[10px]'>
                <button onClick={() => setCommentModalVisible(!commentModalVisible)} className='commentButton showAll mr-[0px] p-[5px] px-[26px] rounded-full border-[#c6c6c6] border-[0px] text-[#cc3333]'>Show All</button>
              </div>
              <Modal
                isOpen={commentModalVisible}
                onRequestClose={() => setCommentModalVisible(false)}
                contentLabel="TM Modal"
                style={{
                  content: {
                    width: '50%', // Set the desired width here
                    height: '70%', // Set the desired height here
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  },
                }}
              >
                <h1 className='font-bold text-[30px] mb-[25px]'>Comments</h1>
                  {replies.map((reply) => (
                    <div className='w-[100%] h-[50px] mt-[15px] mb-[40px]'>
                      <div className='w-[100%] flex items-center'>
                        <img src={reply.photoURL} className='w-[45px] h-[45px] object-cover rounded-full' />
                        <div className='flex-1'>
                          <h1 className='ml-[10px] text-[20px] font-semibold'>{reply.displayName}</h1>
                          <h1 className='ml-[10px] mt-[-5px]'>@{reply.displayName}</h1>
                        </div>
                        <p className='mr-[10px]'>{formatDate(reply)}</p>
                      </div>
                      <p className='mt-[5px]'>{reply.textContent}</p>
                    </div>
                  ))}
              </Modal>
            </div>
            )}
        </div>
      )}
    </div>
  )
}


export default Post