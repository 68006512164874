import React, { useCallback, useState, useContext } from 'react';
import axios from "axios"
import loadingAnimation from "../../assets/animations/loadinganimation.json"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Lottie from 'react-lottie';
import Male from "../../assets/icons/male.svg"
import Female from "../../assets/icons/female.svg"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AuthContext } from '../../context/AuthContext'
import "./style.css";
import { useDropzone } from 'react-dropzone';
import { updateUserInFirebase } from '../../functions/updateUserInFirebase';
import { useNavigate } from 'react-router-dom';

const SignupInfoForm = ({ bio, setBio, loading, setLoading, displayName, photoURL, setPhotoURL, setUserCreated }) => {
  const { user, setUser } = useContext(AuthContext)

  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
  
      const response = await axios.post(
        'https://earthscene.co/wp-json/wp/v2/media',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
  
      const imageUrl = response.data.source_url;
      setPhotoURL(imageUrl);
    } catch (error) {
      console.error('Error uploading file:', error);
  
      // Log more details for debugging
      console.error('Response status:', error.response?.status);
      console.error('Response data:', error.response?.data);
    }
  }, [user.token]);

  const [selectedDate, setSelectedDate] = useState(null);
  const [gender, setGender] = useState("");
  const navigate = useNavigate("")
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [otherGenderInput, setOtherGenderInput] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleFocus = (index) => {
    const elements = document.getElementsByClassName('animated-border');
    for (let i = 0; i < elements.length; i++) {
      if (i === index) {
        elements[i].classList.add('focused');
      } else {
        elements[i].classList.remove('focused');
      }
    }
  };

  console.log()

  const updateUser = async (e) => {
    e.preventDefault()

    setLoading(true)

    if (gender !== "other") {
      const message = await updateUserInFirebase(displayName, bio, gender, selectedDate, photoURL, user.uid)

      if (message.type === "error") {
        console.error(message.message)
      } else {
        await setLoading(false)

        await navigate("/")
      }

      setUser({
        ...user,
        photoURL: photoURL, 
      })
      
      setUserCreated(true)
    } else {
      const message = await updateUserInFirebase(displayName, bio, otherGenderInput, selectedDate, photoURL, user.uid)

      if (message.type === "error") {
        console.error(message.message)
      } else {
        await setLoading(false)

        await navigate("/")
      }

      setUser({
        ...user,
        photoURL: photoURL, 
      })
      
      setUserCreated(true)
    } 
  }

  return (
    <div className='w-[100%] h-[100%] py-[0px]'>
      <div className='w-[100%] h-[170px] p-[10px] border-[#b1b1b1] border-[1px] outline-none flex items-center mt-[20px] animated-border rounded-[10px]'>
        <textarea
          placeholder='Tell us about yourself... (optional)'
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          onFocus={() => handleFocus(2)}
          className='w-[100%] h-[100%] outline-none border-none bg-transparent text-[#999] resize-none'
        />
      </div>
      <div className="date-picker-container">
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        placeholderText='Date of Birth (optional)'
        onFocus={() => handleFocus(2)}
        className='w-[100%] h-[100%] cursor-pointer outline-none border-none bg-transparent text-[#999] resize-none mt-[13px]'
        calendarClassName="custom-datepicker"
      />
    </div>
      <h1 className='mt-[10px] text-[17px] text-[#888]'>Gender (optional)</h1>
      <div className='bg-white w-[100%] h-[50px] border-[1px] border-[#b1b1b1] mt-[4px] rounded-[10px] flex items-center justify-between relative z-20'>
        <div className={`flex items-center justify-center h-[50px] w-[33.33333333333%] rounded-tl-[10px] rounded-bl-[10px] border-r-[1px] border-[#b1b1b1] gender ${gender === "male" ? "gender-selected" : ""}`} onClick={() => setGender("male")}>
          <img src={Male} className='w-[13px] object-contain' />
        </div>
        <div className={`flex items-center justify-center h-[50px] w-[33.33333333333%] border-r-[1px] border-[#b1b1b1] gender ${gender === "female" ? "gender-selected" : ""}`} onClick={() => setGender("female")}>
          <img src={Female} className='w-[18px] object-contain' />
        </div>
        <div className={`flex items-center justify-center h-[50px] w-[33.33333333333%] rounded-tr-[10px] rounded-br-[10px] gender ${gender === "other" ? "gender-selected" : ""}`} onClick={() => setGender("other")}>
          Other
        </div>
      </div>
      {gender === "other" && (
        <div className='relative z-10 animate-slideDown'>
        <input
          value={otherGenderInput}
          placeholder='Enter your Gender'
          onChange={(e) => setOtherGenderInput(e.target.value)}
          className='z-20 w-[100%] h-[55px] text-[#888] border-[1px] border-[#e1e1e1] rounded-bl-[10px] rounded-br-[10px] absolute top-[-10px] px-[10px] pt-[5px]'
        />
        </div>
      )}
      <div {...getRootProps()} className={`flex items-center justify-center w-[100%] h-[26%] border-[2px] border-[#c3c3c3] mt-[10px] border-dashed rounded-[12px] ${isDragActive ? "border-[1px] border-[#517876] " : ""}`}>
        <input {...getInputProps()} type="text" />
        {/* Change this later */}
        <h1 className='text-[17px] text-[#878787]'>
        {photoURL ? (
          `You're currently selecting: ${photoURL.split('/').pop()}`
        ) : (
          isDragActive ? 'Drop the files here!' : 'Drag and drop your profile picture or upload it'
        )}
        </h1>
      </div>
      <div className='w-[100%] h-[45px] flex items-center justify-center mt-[26px] bottom-[35px]'>
      <button
        className='flex items-center justify-center w-[40%] h-[100%] bg-[#476C6A] text-[#fff] rounded-full font-bold button-animation nohover signup_button' 
        disabled={loading}
        onClick={(e) => {
          updateUser(e)
        }}>
        {!loading ? (
            <>
                <p className='font-bold'>Signup</p>
                <ArrowForwardIcon />
            </>
        ) : (
            <Lottie options={defaultOptions} height={50} width={70} />
        )}
      </button>
      </div>
    </div>
  );
};

export default SignupInfoForm;
