import React from 'react';
import Lottie from 'react-lottie';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmailIcon from "../../assets/icons/Email.svg";
import PasswordIcon from "../../assets/icons/Password.svg";
import UsernameIcon from "../../assets/icons/UsernameIcon.svg";
import Name from "../../assets/icons/Name.svg";
import loadingAnimation from "../../assets/animations/loadinganimation.json"
import './style.css';

const SignupForm = ({
    email,
    setEmail,
    password,
    setPassword,
    displayName,
    setDisplayname,
    error,
    errorMessage,
    setActiveMenu,
    loading,
    setLoading,
    fullname,
    setFullName,
    termsChecked,
    setTermsChecked,
    createUser
}) => {
    const handleFocus = (index) => {
        const elements = document.getElementsByClassName('animated-border');
        for (let i = 0; i < elements.length; i++) {
            if (i === index) {
                elements[i].classList.add('focused');
            } else {
                elements[i].classList.remove('focused');
            }
        }
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };

    return (
        <form className={`w-[100%] h-[35%] ${error ? "mt-[10px]" : "mt-[20px]"}`}>
        {error && (
            <div className='w-[100%] h-[60px] bg-[#ffe6e6] text-[#e55c59] rounded-[5px] border-[1px] border-[#e55c59] mb-[15px] flex items-center px-[20px]'>
                {errorMessage}
            </div>
        )}
            <div className='w-[100%] h-[50px] flex items-center justify-between mb-[20px]'>
                <div className='w-[100%] h-[50px] rounded-full px-[15px] border-[#b1b1b1] border-[1px] outline-none flex items-center animated-border'>
                    <img src={Name} className='w-[25px] h-[28px]' alt="EmailIcon" />
                    <input
                        type="text"
                        placeholder='First Name'
                        value={fullname.first}
                        onChange={(e) => setFullName({ first: e.target.value, last: fullname.last })}
                        onFocus={() => handleFocus(0)}
                        className='w-[100%] h-[100%] outline-none border-none bg-transparent ml-[10px] text-[#999]'
                    />
                </div>
                <div className='w-[100%] h-[50px] rounded-full px-[15px] border-[#b1b1b1] ml-[10px] border-[1px] outline-none flex items-center animated-border'>
                    <img src={Name} className='w-[25px] h-[28px]' alt="EmailIcon" />
                    <input
                        type="text"
                        placeholder='Last Name'
                        value={fullname.last}
                        onChange={(e) => setFullName({ first: fullname.first, last: e.target.value })}
                        onFocus={() => handleFocus(1)}
                        className='w-[100%] h-[100%] outline-none border-none bg-transparent ml-[10px] text-[#999]'
                    />
                </div>
            </div>
            <div className='w-[100%] h-[50px] rounded-full px-[15px] border-[#b1b1b1] border-[1px] outline-none flex items-center animated-border'>
                <img src={EmailIcon} className='w-[25px] h-[28px]' alt="EmailIcon" />
                <input
                    type="text"
                    placeholder='Email Address'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => handleFocus(2)}
                    className='w-[100%] h-[100%] outline-none border-none bg-transparent ml-[10px] text-[#999]'
                />
            </div>
            <div className='w-[100%] h-[50px] rounded-full px-[15px] border-[#b1b1b1] border-[1px] outline-none flex items-center mt-[20px] animated-border'>
                <img src={PasswordIcon} className='w-[25px] h-[28px]' alt="PasswordIcon" />
                <input
                    type="password"
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => handleFocus(3)}
                    autoComplete="current-password"
                    className='w-[100%] h-[100%] outline-none border-none bg-transparent ml-[10px] text-[#999]'
                />
            </div>
            <div className='w-[100%] h-[50px] rounded-full px-[15px] border-[#b1b1b1] border-[1px] outline-none flex items-center mt-[20px] animated-border'>
                <img src={UsernameIcon} className='w-[25px] h-[28px]' alt="UsernameIcon" />
                <input
                    type="text"
                    placeholder='What do you want to go by?'
                    value={displayName}
                    onChange={(e) => setDisplayname(e.target.value)}
                    onFocus={() => handleFocus(4)}
                    className='w-[100%] h-[100%] outline-none border-none bg-transparent ml-[10px] text-[#999]'
                />
            </div>
            <div className='w-[100%] mt-[17px] px-[8px] flex items-center cursor-pointer'>
                <label htmlFor="termsCheckbox" className="w-full flex items-center cursor-pointer">
                    <input
                        id="termsCheckbox"
                        value={termsChecked}
                        onChange={() => setTermsChecked(!termsChecked)}
                        type="checkbox"
                        className='w-[13px] h-[13px]'
                    />
                    <h1 className='ml-[7px] text-[#999] text-[13px]'>
                        I've read and agree with the <span className='text-red-500 underline'>Terms of Service</span> and <span className='text-red-500 underline'>Privacy Policy</span>
                    </h1>
                </label>
            </div>
            <div className='w-[100%] h-[45px] flex items-center justify-center mt-[26px]'>
                <button 
                    className='flex items-center justify-center w-[40%] h-[100%] bg-[#476C6A] text-[#fff] rounded-full font-bold button-animation nohover' 
                    disabled={loading}
                    onClick={(e) => {
                        createUser(e)
                    }}>
                    {!loading ? (
                        <>
                            <p className='font-bold'>Next</p>
                            <ArrowForwardIcon />
                        </>
                    ) : (
                        <Lottie options={defaultOptions} height={50} width={70} />
                    )}
                </button>
            </div>
        </form>
    );
};

export default SignupForm;
