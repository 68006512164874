import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import Post from '../../components/Post';
import { Header } from '../../components';

const Saved = () => {
    const { user } = useContext(AuthContext)
    const [savedPosts, setSavedPosts] = useState([]);
    const [postDocs, setPostDocs] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
  
          const savedPostsPromise = new Promise((resolve) => {
            const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
              if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                const savedPostsData = userData.saved || [];
                resolve(savedPostsData);
              }
            });
            return () => unsubscribe();
          });
  
          const savedPostsData = await savedPostsPromise;
          setSavedPosts(savedPostsData);
  
          const postDocsPromises = savedPostsData.map(async (postId) => {
            const postDocRef = doc(db, 'posts', postId);
            const postDocSnapshot = await getDoc(postDocRef);
  
            if (postDocSnapshot.exists()) {
              return {
                postId: postDocSnapshot.id,
                ...postDocSnapshot.data(),
              };
            }
          });
  
          const postsData = await Promise.all(postDocsPromises);
          setPostDocs(postsData.filter(Boolean));
        }
      };
  
      fetchData();
    }, [user]);

    console.log(postDocs[0])

  return (
    <>
    <Header />
    <div className='w-[100%]flex items-center flex-col overflow-scroll'>
      <div className='w-[100%] mt-[10px] flex items-center flex-col'>
      {postDocs.map((post) => (
        <Post 
          post={post}
        />
      ))}
      </div>
    </div>
    </>
  )
}

export default Saved